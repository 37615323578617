import {Cancel} from '@mui/icons-material';
import {Chip, MenuItem, Paper, TextField, Typography, emphasize} from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import {GroupBase} from 'react-select';
declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    classes?: any;
    TextFieldProps?: any;
  }
}
export const inputStyle = {
  display: 'flex',
  padding: 0,
  paddingLeft: '4px',
  height: 'auto',
  //minHeight: '40px',
};
const fontSize = '14px';
export const styles = (theme: any) => ({
  root: {
    flexGrow: 1,
    //height: 250,
    minWidth: 290,
    fontSize,
  },
  //input: inputStyle,
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    paddingLeft: theme.spacing(1),
    '& .MuiTypography-root': {
      padding: 0,
    },
  },
  chip: {
    //margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize,
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    // marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(1),
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: {classes, TextFieldProps},
  } = props;

  return (
    <TextField
      InputProps={{
        inputComponent: `div`,
        inputProps: {
          style: inputStyle,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}>
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  const {selectProps, innerProps = {}, children} = props;
  return (
    <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<Cancel {...props.removeProps} />}
      size="small"
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

export const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};
