const incidentEnumerators = {
  //incidentType: ['Injury', 'Illness', 'NearMiss', 'PropertyDamage', 'Environmental', 'Vehicle', 'Other'],
  initialClassification: ['WorkAccident', 'CommutingAccident', 'OccupationalDisease',],// 'NotCoveredByLaw', 'NotApplicable'],
  finalClassification: ['WorkAccident', 'CommutingAccident', 'OccupationalDisease',],// 'NotCoveredByLaw', 'NotApplicable'],
  severity: ['Mild', 'Severe',],//, 'Fatal'],
  incidentStatus: ['Open', 'Closed'],
  withLostDaysSelector: ['yes', 'no'],
  contractType: ['Unspecified', 'Indefinite', 'FixedTerm', 'Other'],
};

export default incidentEnumerators;
