import {Box, Chip, Grid, IconButton} from '@mui/material';
import MaterialLink from '@mui/material/Link';
import {makeStyles} from '@mui/styles';
import debounce from 'lodash/debounce';
import _uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {i18n} from 'src/i18n';
import authActions from 'src/modules/auth/authActions';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import FormErrors from 'src/view/shared/form/formErrors';

import {Add} from '@mui/icons-material';
import {
  components as materialUiComponents,
  styles as materialUiStyles,
} from 'src/view/shared/form/items/shared/reactSelectMaterialUi';
import {DisabledItem} from './shared/formItemWrappers';

const useStyles = makeStyles(materialUiStyles as any);

const AUTOCOMPLETE_SERVER_FETCH_SIZE = 20;

function useFocusableInput(shouldFocus: boolean) {
  const inputRef = useRef<any>(null);
  const setInputRef = (instance: any) => {
    inputRef.current = instance;
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (shouldFocus) {
      timeout = setTimeout(() => {
        inputRef.current?.focus();
      });
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [shouldFocus]);

  return {
    setInputRef,
  };
}
function AutocompleteInMemoryFormItem(props) {
  const {
    control,
    setValue,
    register,
    formState: {touchedFields, isSubmitted, errors},
    getValues,
  } = useFormContext();

  const {
    noLink,
    label,
    name,
    hint,
    placeholder,
    autoFocus,
    externalErrorMessage,
    mode,
    required,
    isClearable,
    mapper,
    fetchFn,
    disabled,
    entity,
    defaultValue,
    fetchAll,
    customFetchSize,
    skipInitialFetch,
  } = props;

  const dispatch = useDispatch();

  const originalValue: any = useWatch({name, control, defaultValue});
  //console.log('%c⧭ AutocompleteInMemoryFormItem', 'color: #ace2e6', {name, originalValue});

  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    register(name);
    setValue(name, originalValue);
  }, []);

  const fetchSize = customFetchSize || AUTOCOMPLETE_SERVER_FETCH_SIZE;

  const isFirstFetch = useRef(true);

  useEffect(() => {
    const firstFetchFn = async () => {
      setLoading(true);

      try {
        const hasValueWithId = Array.isArray(defaultValue) ? defaultValue.some((item) => item?.id) : defaultValue?.id;
        // console.log('%c⧭ firstFetchFn 1 ', 'color: #ace2e6', {entity, defaultValue, skipInitialFetch, hasValueWithId});
        if (skipInitialFetch && hasValueWithId) {
          return;
        }
        const fetchSelectedQuery = (
          Array.isArray(defaultValue)
            ? defaultValue.map((item) => item?.id || item?.label || item)
            : [defaultValue?.id || defaultValue]
        ).filter(Boolean);

        // console.log('%c⧭ firstFetchFn 2', 'color: #ace2e6', {entity, defaultValue, fetchSelectedQuery});

        let selectedValue: any;

        const latestSearches = AuthCurrentTenant.getItem('latestSearches', '{}') || {};
        const latestSearchSelection = (entity ? latestSearches[entity] : []) || [];

        const firstQuery = fetchSelectedQuery.concat(latestSearchSelection).filter(Boolean);
        // console.log('%c⧭ firstFetchFn 3', 'color: #cc7033', {
        //   entity,
        //   latestSearches,
        //   latestSearchSelection,
        // });

        const fetchSelectedLimit = firstQuery.length;
        if (fetchSelectedLimit > 0) {
          selectedValue = (await fetchFn(firstQuery, fetchSelectedLimit)) || [];
          if (selectedValue?.length > 0) {
            selectedValue.sort((a, b) => firstQuery.findIndex((id) => id === a.id) - firstQuery.findIndex((id) => id === b.id));
          }
        }
        let dataSource: any = [];
        if (!disabled) {
          dataSource = (await fetchFn(undefined, fetchAll ? undefined : fetchSize)) || [];
        }
        dataSource = addSelectedToDataSource(dataSource, selectedValue);

        if (entity === 'modelo-tarea' && mode !== 'multiple' && originalValue) {
          dataSource.some((item) => {
            if (item?.id === (originalValue?.id || originalValue)) {
              props.onChange && props.onChange(item);
              return true;
            }
            return false;
          });
        }

        // console.log('%c⧭ setDataSource', 'color: #f27999', dataSource);
        setDataSource(dataSource?.filter(Boolean).filter((item) => item?.id) || []);
      } catch (error) {
        console.error(error);
        setDataSource([]);
        return [];
      }
    };

    firstFetchFn().then(() => {
      if (isFirstFetch.current) {
        isFirstFetch.current = false;
      }
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  const prioritizeFromDataSource = (selected) => {
    return (dataSource || []).find((item) => item?.id === selected?.id || item?.id === selected) || selected;
  };

  const value = () => {
    if (mode === 'multiple') {
      return valueMultiple();
    } else {
      return valueOne();
    }
  };

  const valueMultiple = () => {
    if (originalValue && Array.isArray(originalValue)) {
      return originalValue?.map((value) => prioritizeFromDataSource(value));
    }

    return [];
  };

  const valueOne = () => {
    if (originalValue) {
      return prioritizeFromDataSource(originalValue);
    }

    return null;
  };

  const handleSelect = (value) => {
    if (mode === 'multiple') {
      return handleSelectMultiple(value);
    } else {
      return handleSelectOne(value);
    }
  };

  const updateLatestSearches = async (values) => {
    //console.log('%c⧭ updateLatestSearches', 'color: #ff0000', values);
    if (entity) {
      dispatch(authActions.updateLatestSearches(entity, values));
    }
  };
  const debounceUpdateLatestSearches = useCallback(debounce(updateLatestSearches, 1000), []);

  const handleSelectMultiple = (values) => {
    // console.log('%c⧭ handleSelectMultiple', 'color: #aa00ff', values);
    if (!values?.length) {
      setValue(name, [], {shouldValidate: true});
      props.onChange && props.onChange([]);
      return;
    }

    // get previous values to identify which is the new one by diff
    const previousValues = originalValue || [];

    // get new values by diff
    const newValues = values.filter(Boolean);
    // console.log('%c⧭ newValues', 'color: #e50000', newValues);
    const newValue = newValues.find((value) => !previousValues.some((prevValue) => prevValue?.id === value?.id));

    setValue(name, newValues, {shouldValidate: true});
    props.onChange && props.onChange(newValues);

    // update latest searches
    if (newValue) {
      setDataSource(addSelectedToDataSource(dataSource, newValue));
      debounceUpdateLatestSearches([newValue?.id]);
    }
  };

  const handleSelectOne = (value) => {
    if (!value) {
      setValue(name, null, {shouldValidate: true});
      props.onChange && props.onChange(null);
      return;
    }

    const newValue = value; //mapper.toValue(value);

    setValue(name, newValue, {shouldValidate: true});
    props.onChange && props.onChange(newValue);

    // update latest searches
    if (newValue) {
      setDataSource(addSelectedToDataSource(dataSource, newValue));
      debounceUpdateLatestSearches([newValue?.id]);
    }
  };

  const addSelectedToDataSource = (dataSource, selectedValue?) => {
    if (!dataSource) {
      return [];
    }

    selectedValue = selectedValue || value();
    // Includes the selected value on the dataSource
    if (selectedValue) {
      if (Array.isArray(selectedValue)) {
        return _uniqBy([...selectedValue, ...dataSource], 'id');
      } else {
        return _uniqBy([selectedValue, ...dataSource], 'id');
      }
    }

    return dataSource;
  };

  const hintOrLoading = loading ? i18n('autocomplete.loading') : hint;

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  const handleSearch = async (value) => {
    //console.log('%c⧭ handleSearch', 'color: #ace2e6', value);
    try {
      const results = await fetchFn(value, fetchSize);
      if (isFirstFetch.current) {
        isFirstFetch.current = false;
      }
      return results || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const marginOptions = {
    //normal: {marginTop: '16px', marginBottom: '8px'},
    none: {marginTop: '0px', marginBottom: '0px'},
  };
  const controlStyles = {
    container: (provided) => ({
      ...provided,
      width: props.maxContent ? 'max-content' : '100%',
      minWidth: props.minWidth ? props.minWidth : props.hasPermissionToCreate && props.showCreate ? 'calc(100% - 40px)' : '100%',
      ...marginOptions[props.margin || 'none'],
    }),
    control: (provided) => ({
      ...provided,
      borderColor: Boolean(errorMessage) ? 'red' : undefined,
    }),
    /** El menu portal solo afecta cuando el props menuPortalTarget={document.body} está activo */
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
      //zoom: props.menuZoom || 0.8,
    }),
  };
  if (props.fixedBase) {
    //controlStyles['menuList'] = base => ({...base, position: 'fixed !important', backgroundColor: 'white', border: '1px solid lightgray', width: '20rem'});
    controlStyles['menuList'] = (base) => ({...base, zIndex: 9999});
  }
  const classes = useStyles();

  const displayReadOnlyRecord = (item) => {
    if (!item) return;
    let displayItem = item;
    if (!item.label) {
      displayItem = mapper?.toAutocomplete ? mapper.toAutocomplete(item) : item;
    }

    return (
      <Box>
        {noLink || !entity ? (
          <Chip label={displayItem.label} variant="outlined" />
        ) : (
          <MaterialLink component={Link} to={`/${entity}/${item.id}`}>
            <Chip label={displayItem.label} variant="outlined" />
          </MaterialLink>
        )}
      </Box>
    );
  };

  const focusableInput = useFocusableInput(autoFocus).setInputRef;

  const computedValues = value();
  // console.log('%c⧭ computedValues', 'color: #00258c', computedValues);

  if (disabled) {
    //console.log('%c⧭ disabled values', 'color: #735656', label, entity, values, mode);

    return (
      <DisabledItem
        label={label}
        show={(mode === 'multiple' && computedValues?.length > 0) || (mode !== 'multiple' && computedValues)}
        item={
          mode === 'multiple' ? (
            computedValues.map((raw, index) => (
              <Grid key={index} style={{marginBottom: 10}} container>
                {displayReadOnlyRecord(raw)}
              </Grid>
            ))
          ) : (
            <Grid item>{displayReadOnlyRecord(computedValues)}</Grid>
          )
        }
      />
    );
  }

  return (
    <div style={{display: 'flex', alignItems: 'start'}}>
      <AsyncSelect
        //ref={focusableInput as any}
        ref={focusableInput as any}
        styles={controlStyles}
        menuPortalTarget={props.menuPortalTarget}
        classes={classes}
        inputId={name}
        TextFieldProps={{
          //inputRef: focusableInput as any,
          label,
          required,
          variant: 'outlined',
          fullWidth: true,
          error: Boolean(errorMessage),
          helperText: errorMessage || hint,
          size: 'small',
          InputLabelProps: {
            shrink: true,
          },
        }}
        getOptionLabel={(option) => (mapper?.toAutocomplete ? mapper.toAutocomplete(option).label : option.label)}
        getOptionValue={(option) => option.id}
        components={materialUiComponents}
        defaultOptions={dataSource}
        isMulti={mode === 'multiple' ? true : false}
        loadOptions={handleSearch}
        placeholder={placeholder || ''}
        onChange={handleSelect}
        onBlur={() => props.onBlur && props.onBlur(null)}
        value={computedValues}
        isClearable={isClearable}
        loadingMessage={() => i18n('autocomplete.loading')}
        noOptionsMessage={() => (loading ? i18n('autocomplete.loading') : i18n('autocomplete.noOptions'))}
      />

      {props.endButtonMargin ||
        (props.hasPermissionToCreate &&
          props.showCreate && ( // && (
            <IconButton
              style={{
                marginLeft: '0px',
                marginTop: '10px',
                marginBottom: '3px',
                flexShrink: 0,
                opacity: props.endButtonMargin ? 0 : 1,
              }}
              color="secondary"
              disabled={props.endButtonMargin}
              onClick={props.onOpenModal}>
              <Add />
            </IconButton>
          ))}
    </div>
  );
}

AutocompleteInMemoryFormItem.defaultProps = {
  isClearable: true,
  mode: 'default',
  required: false,
};

AutocompleteInMemoryFormItem.propTypes = {
  fetchFn: PropTypes.func.isRequired,
  mapper: PropTypes.object.isRequired,
  required: PropTypes.bool,
  mode: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  isClearable: PropTypes.bool,
  showCreate: PropTypes.bool,
  hasPermissionToCreate: PropTypes.bool,
  margin: PropTypes.string,
  menuPortalTarget: PropTypes.object,
  minWidth: PropTypes.number,
  noLink: PropTypes.bool,
  fetchAll: PropTypes.bool,
  customFetchSize: PropTypes.number,
  skipInitialFetch: PropTypes.bool,
};

export default AutocompleteInMemoryFormItem;
