import {i18n} from 'src/i18n';
import IncidentService from 'src/modules/incident/incidentService';
import listActions from 'src/modules/incident/list/incidentListActions';
import Errors from 'src/modules/shared/error/errors';
import {getHistory} from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'INCIDENT_DESTROY';

const incidentDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy:
    (id, deleted: any = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: incidentDestroyActions.DESTROY_STARTED,
        });

        await IncidentService.destroyAll([id]);

        dispatch({
          type: incidentDestroyActions.DESTROY_SUCCESS,
        });

        Message.success(i18n('entities.incident.destroy.success'));

        dispatch(listActions.doFetchCurrentFilter());

        if (deleted) {
          deleted(id);
        } else {
          getHistory().push('/incidente');
        }
      } catch (error) {
        Errors.handle(error);

        dispatch(listActions.doFetchCurrentFilter());

        dispatch({
          type: incidentDestroyActions.DESTROY_ERROR,
        });
      }
    },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: incidentDestroyActions.DESTROY_ALL_STARTED,
      });

      await IncidentService.destroyAll(ids);

      dispatch({
        type: incidentDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(i18n('entities.incident.destroyAll.success'));

      //getHistory().push('/incident');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: incidentDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default incidentDestroyActions;
