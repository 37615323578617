import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { i18n } from 'src/i18n';
import layoutActions from 'src/modules/layout/layoutActions';
import actions from 'src/modules/perfilEmpleado/form/perfilEmpleadoFormActions';
import selectors from 'src/modules/perfilEmpleado/form/perfilEmpleadoFormSelectors';
import { getHistory } from 'src/modules/store';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import PerfilEmpleadoForm from 'src/view/perfilEmpleado/form/PerfilEmpleadoForm';
import Breadcrumb from 'src/view/shared/Breadcrumb';
import Spinner from 'src/view/shared/Spinner';
import PageTitle from 'src/view/shared/styles/PageTitle';

function PerfilEmpleadoFormPage(props) {
  const [dispatched, setDispatched] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const initLoading = useSelector(
    selectors.selectInitLoading,
  );
  const saveLoading = useSelector(
    selectors.selectSaveLoading,
  );
  const record = useSelector(selectors.selectRecord);

  const isEditing = Boolean(
    props.record?.id || match.params.id,
  );

  const title = isEditing
    ? i18n('entities.perfilEmpleado.edit.title')
    : i18n('entities.perfilEmpleado.new.title');

  useEffect(() => {
    dispatch(
      actions.doInit(props.record?.id || match.params.id),
    );
    setDispatched(true);
  }, [dispatch, match.params.id]);

  const doSubmit = (id, data) => {
    if (isEditing) {
      dispatch(actions.doUpdate(id, data));
    } else {
      dispatch(actions.doCreate(data));
    }
  };

  const closeDrawer = () => dispatch(
    layoutActions.doVisibleDrawer({
      drawerId: 'DrawerPerfilEmpleadoFormPage',
      drawerVisible: false,
      drawerChanged: moment().unix(),
      drawerUpdateComponent: 'PerfilEmpleadoListPage',
    })
  );

  return (
    <>
      {!props.drawer ||
        (!props.disabled && (
          <Breadcrumb
            items={[
              [i18n('dashboard.menu'), '/'],
              [
                i18n('entities.perfilEmpleado.menu'),
                '/perfil-empleado',
              ],
              [title],
            ]}
          />
        ))}

      <ContentWrapper>
        {!props.disabled && <PageTitle>{title}</PageTitle>}

        {initLoading && <Spinner />}

        {dispatched && !initLoading && (
          <PerfilEmpleadoForm
            disabled={props.disabled}
            drawer={props.drawer}
            saveLoading={saveLoading}
            initLoading={initLoading}
            record={record}
            isEditing={isEditing}
            onSubmit={doSubmit}
            onCancel={() => props.drawer ? closeDrawer() : getHistory().push('/perfil-empleado')}
            contractorsView={props.contractorsView}
          />
        )}
      </ContentWrapper>
    </>
  );
}

export default PerfilEmpleadoFormPage;
